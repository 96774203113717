<template>
    <v-container class="list-bg min-h-full">
        <VTopToolbar title="Tasks">
            <!-- Override right nav button -->
            <template slot="right">
                <v-icon v-visible="!loading" @click="showFilterOptions()">
                    {{anyFilters ? 'mdi-filter' : 'mdi-filter-outline'}}
                </v-icon>
            </template>
            <template slot="right">
                <v-icon v-visible="!loading" @click="refresh()">
                    mdi-cached
                </v-icon>
            </template>
        </VTopToolbar>

        <div v-show="loading">
            <v-skeleton-loader
                type="image, text, image, text, image"
            ></v-skeleton-loader>
        </div>

        <h4
            class="grey-text font-weight-light mt-3 mb-5"
            v-if="!loading && !tasks.length"
        >
            There are no tasks.
        </h4>

        <v-card
            class="mx-auto mb-3"
            outlined
            v-show="!loading"
            v-for="task in tasks"
            :key="task.id"
        >
            <div class="d-flex" @click.stop="navTo(task)">
                <div class="flex-grow-1">
                    <v-card-title class="align-start flex-nowrap mb-3">
                        <div class="flex-grow-0">
                            <v-icon left>{{ task.icon }}</v-icon>
                        </div>
                        <div class="flex-grow-1" style="word-break: break-word">
                            <span
                                class="
                                    font-weight-bold
                                    grey--text
                                    text--darken-3
                                "
                                >{{ task.title }}</span
                            >
                        </div>
                    </v-card-title>

                    <v-card-subtitle class="font-weight-bold pb-1">
                        {{ task.type }} - {{ task.phase }}
                    </v-card-subtitle>

                    <v-card-text class="grey--text text--darken-1">
                        <div class="text-truncate">
                            {{ task.reference || "No reference" }}
                        </div>
                        <div class="text-truncate">
                            <v-icon
                                left
                                small
                                style="vertical-align: inherit"
                                >{{ task.primaryOwnerIcon }}</v-icon
                            >
                            <span>{{ task.primaryOwner }}</span>
                        </div>
                    </v-card-text>
                </div>

                <div class="flex-grow-0 d-flex">
                    <v-icon large color="grey lighten-2"
                        >mdi-chevron-right</v-icon
                    >
                </div>
            </div>
            <v-divider></v-divider>

            <v-card-actions class="my-1">
                <v-datetime-traffic-light :value="task.createdDate" />
                <v-spacer></v-spacer>
                <v-icon small color="grey" v-if="task.anyDocs" class="mr-2"
                    >mdi-paperclip</v-icon
                >
                <!-- <v-icon small color="grey" class="mr-1" @click.stop="showOptions(task)">mdi-dots-horizontal</v-icon> -->
            </v-card-actions>
        </v-card>

        <v-btn
            outlined
            rounded
            block
            color="primary"
            v-show="!loading && hasMore"
            :loading="loadingMore"
            @click.stop="loadNextPage()"
        >
            More
        </v-btn>
    </v-container>
</template>

  
<script>
import tasksAgent from "./tasksAgent";
import { InstallPrompt, SharedoProfile } from "@sharedo/mobile-core";

const rpp = 10;

export default {
    data() {
        return {
            loading: true,
            loadingMore: false,
            tasks: [],
            lastPageLoaded: 0,
            hasMore: true,
            filter: {
                scope: null,
            },
        };
    },

    created() {
        this.persona = SharedoProfile.profile.persona;

        // Engineer -> redirect to instruction list
        if (!this.isFeeEarner) {
            this.$router.push({ name: "cpdInstructions" });
        }
    },

    mounted() {
        InstallPrompt.init();

        this.loadPage()
            .then((result) => {
                this.hasMore = result.hasMore;
            })
            .catch(console.error);
    },

    computed: {
        isFeeEarner() {
            return this.persona === "fee-earner";
        },
        anyFilters() {
            return !!this.filter.scope;
        },
    },

    methods: {
        loadPage(refresh) {
            return new Promise((resolve, reject) => {
                this.lastPageLoaded++;

                tasksAgent
                    .getMyTasks(rpp, this.lastPageLoaded, this.filter.scope)
                    .then((data) => {
                        this.loading = false;
                        var models = data.results.map((t) => ({
                            id: t.id,
                            reference: t.data.reference,
                            title: t.data.title,
                            createdDate: new Date(
                                t.data["createdDate.utc.value"]
                            ),
                            due: new Date(
                                t.data["taskDueDate.date.local.value"]
                            ),
                            icon: t.data["type.iconClass"],
                            type: t.data["type.name"],
                            phase: t.data["phase.name"],
                            primaryOwner:
                                t.data["roles.primary-owner.ods.name"],
                            primaryOwnerIcon:
                                t.data[
                                    "roles.primary-owner.participantType.iconClass"
                                ],
                            anyDocs: !!t.data["documents!1.title"],
                            link: "/tasks/" + t.id,
                        }));

                        models.forEach((m) => {
                            this.tasks.push(m);
                        });

                        var totalPages = Math.ceil(data.totalCount / rpp);
                        var hasMore = totalPages > this.lastPageLoaded;

                        resolve({ hasMore: hasMore });
                    })
                    .catch(console.error);
            });
        },

        refresh() {
            this.lastPageLoaded = 0;
            this.loading = true;

            // This should also cause a scroll to top
            this.tasks.splice(0, this.tasks.length);

            this.loadPage(true)
                .then((result) => {
                    this.hasMore = result.hasMore;
                })
                .catch(console.error);
        },

        loadNextPage() {
            this.loadingMore = true;
            this.loadPage()
                .then((result) => {
                    this.loadingMore = false;
                    if (!result.hasMore) {
                        this.hasMore = false;
                    }
                })
                .catch(console.error);
        },

        navTo(task) {
            this.$router.push({ path: task.link });
        },

        showOptions(task) {
            this.$coreUi.actionSheet({
                items: [
                    {
                        text: "Open",
                        color: "primary",
                        icon: "mdi-arrow-right-circle",
                        handler: this.navTo.bind(this, task),
                    },
                ],
            });
        },

        showFilterOptions() {
            
            var opts = [];

            opts.push({
                text: "Show all",
                icon: "mdi-eye-outline",
                color: "success",
                handler: () => {
                    this.filter.scope = null;
                    this.refresh();
                }
            });

            opts.push({
                text: "Assigned to me",
                icon: "mdi-account-outline",
                color: this.filter.scope === "me" ? "primary" : null,
                handler: () => {
                    this.filter.scope = "me";
                    this.refresh();
                }
            });
            
            opts.push({
                text: "Assigned to team",
                icon: "mdi-account-group-outline",
                color: this.filter.scope === "team" ? "primary" : null,
                handler: () => {
                    this.filter.scope = "team";
                    this.refresh();
                }
            });
            
            this.$coreUi.actionSheet({
                items: opts,
            });
        },
    },
};
</script>

<style scoped>
.v-card__title {
    font-size: 1.1rem;
    line-height: 1.5rem;
}

.v-card__title .v-icon.v-icon {
    font-size: 22px;
}

.v-card__text,
.card-text-small {
    font-size: 0.775rem;
}

/* Purposefully unspecific CSS so red--text overrides us */
.task-lag {
    color: var(--v-secondary-darken1);
}
</style>